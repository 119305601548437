<template>
<transition name="fade-in-up">
  <router-view />
</transition>
</template>

<script>
export default {
  name: 'Products',
}
</script>

<style scoped>

</style>
